


























































import { DtoUserLogin, reqUserLogin, reqValidateCode } from "@/api";
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  name: "login"
})
export default class extends Vue {
  imgsrc = "";

  form: DtoUserLogin = {
    userName0: "",
    password0: "",
    validateCode0: ""
  };

  get redirect() {
    return this.$route.query.redirect ?? "/";
  }

  @Mutation
  SET_TOKEN!: (token: string) => void;

  loging = false;
  async loginHandler() {
    try {
      this.loging = true;
      const res = await reqUserLogin(this.form);
      this.SET_TOKEN(res.headers.certification);
      this.$message.success("登录成功");
      this.$router.replace({
        path: this.redirect as string
      });
    } catch (error) {
      const { response } = error;
      this.$message.error(response.data.message);
      this.initValidCode();
    } finally {
      this.loging = false;
    }
  }

  validCodeRequesting = false;
  async initValidCode() {
    try {
      this.validCodeRequesting = true;
      const { data } = await reqValidateCode();
      this.imgsrc = data;
    } catch (error) {
      //
    } finally {
      this.validCodeRequesting = false;
    }
  }

  created() {
    this.initValidCode();
  }
}
